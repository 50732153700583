import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Form = tw.form`
  mx-auto
  max-w-xl
`

const Input = tw.input`
  border
  rounded-md
  bg-gray-100
  my-2
  w-full
`

const RequiredLabel = tw.span`
  rounded-md
  bg-green-400
  text-white
  text-center
  p-1
  mr-4
`

const FormItem = tw.div`
  border-t
  py-4
`

const SendButton = tw.button`
  rounded-md
  bg-blue-400
  text-white
  text-center
  p-2
  px-6
  block
  mr-0
  ml-auto
`

const TextArea = tw.textarea`
  border
  rounded-md
  bg-gray-100
  w-full
  h-32
  my-2
`

const Contact = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout title={siteTitle}>
      <SEO title="お問い合わせ" />
      <Form name="contact" method="POST" data-netlify="true">
        <p tw="mb-4">
          ご質問・ご相談は以下のフォームよりお問い合わせください。
        </p>
        <input type="hidden" name="form-name" value="contact" />
        <FormItem>
          <label>
            <p>
              <RequiredLabel>必須</RequiredLabel>お名前
            </p>
            <Input type="text" name="name" />
          </label>
        </FormItem>
        <FormItem>
          <label>
            <p>
              <RequiredLabel>必須</RequiredLabel>メールアドレス
            </p>
            <Input type="email" name="email" />
          </label>
        </FormItem>
        <FormItem>
          <label>
            <p>
              <RequiredLabel>必須</RequiredLabel>内容
            </p>
            <TextArea name="message"></TextArea>
          </label>
        </FormItem>
        <FormItem>
          <SendButton type="submit">Send</SendButton>
        </FormItem>
      </Form>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query SiteQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
